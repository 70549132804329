import React, { useState, useContext } from 'react';
//import { Container } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import imagem from '../../config/images';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Context } from '../../Context/AuthContext';

import api from '../../config/configAPi';

export const Login = () => {
  const navigate = useNavigate();

  const { authenticated, signIn } = useContext(Context);

  // console.log("usuario" + authenticated);

  const [user, setUser] = useState({
    email: '',
    password: '',
  });

  const [status, setStatus] = useState({
    type: '',
    mensagem: '',
    loading: false,
  });

  const valorInput = (e) =>
    setUser({ ...user, [e.target.name]: e.target.value });

  const loginSubmit = async (e) => {
    console.log('entrou_login');
    e.preventDefault();
    setStatus({
      loading: true,
    });
    const headers = {
      'Content-type': 'application/json',
    };

    console.log('users:', user);

    //   try {
    //     const response = await api.post('/login', user, { headers });

    //     if (response) {
    //       console.log('response', response);
    //       setStatus({
    //         //  type:'success',
    //         //  mensagem:response.data.mensagem,
    //         loading: false,
    //       });
    //       localStorage.setItem('token', response.data.token);
    //       signIn(true);
    //       return navigate('/dashboard');
    //     } else {
    //       console.log('else ', response);
    //     }
    //   } catch (err) {
    //     console.log('Error: ', err);

    //     console.log('erro catch', err.response);
    //     if (err.response) {
    //       setStatus({
    //         type: 'error',
    //         mensagem: err.response.data.mensagem,
    //         loading: false,
    //       });
    //     } else {
    //       console.log(err.response);
    //       setStatus({
    //         type: 'error',
    //         mensagem: 'Tente mais tarde!',
    //         loading: false,
    //       });
    //     }
    //   }
    // };

    await api
      .post('/login', user, { headers })
      .then((response) => {
        console.log('response', response);
        setStatus({
          //  type:'success',
          //  mensagem:response.data.mensagem,
          loading: false,
        });
        localStorage.setItem('token', response.data.token);
        signIn(true);
        return navigate('/dashboard');
      })
      .catch((err) => {
        console.log('erro catch', err.response);
        if (err.response) {
          setStatus({
            type: 'error',
            mensagem: err.response.data.mensagem,
            loading: false,
          });
        } else {
          console.log(err.response);
          setStatus({
            type: 'error',
            mensagem: 'Tente mais tarde!',
            loading: false,
          });
        }
      });
  };
  return (
    <>
      <Container>
        <div className="telaLogin">
          <div className="tituloLogin">
            <img className="ajustaLogo" src={imagem.logo} alt="logo" />
          </div>
          {status.type === 'error' ? (
            <p className="mensagemErro">{status.mensagem}</p>
          ) : (
            ''
          )}
          {status.type === 'success' ? (
            <p className="mensagemSucesso">{status.mensagem}</p>
          ) : (
            ''
          )}
          {status.loading ? <p>Validando...</p> : ''}
          <Form onSubmit={loginSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Usuario</Form.Label>
              <Form.Control
                type="text"
                name="email"
                placeholder="Digite o email"
                onChange={valorInput}
              ></Form.Control>
              <Form.Label>Senha</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Digite a senha"
                onChange={valorInput}
              ></Form.Control>
            </Form.Group>
            <div className="d-grid gap-2">
              {status.loading ? (
                <Button variant="outline-success" type="submit" disabled>
                  Acessando...
                </Button>
              ) : (
                <Button variant="success" type="submit">
                  Acessar
                </Button>
              )}
            </div>
          </Form>
        </div>
      </Container>
    </>
  );
};
